import { createSlice } from '@reduxjs/toolkit'
import { sendContactForm } from './contactAction'

const initialState = {
	contactData: {
		success: false,
		message: '',
		contactRequest: {
			name: '',
			__v: 0,
			_id: '',
			createdAt: '',
			email: '',
			message: '',
			updatedAt: '',
		},
	},
	loader: {
		sendingContactData: false,
	},
}

const contactSlice = createSlice({
	name: 'contact',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(sendContactForm.pending, (state) => {
				state.loader.sendingContactData = true
			})
			.addCase(sendContactForm.fulfilled, (state, action) => {
				state.loader.sendingContactData = false
				if (!action.payload) return
				state.contactData = action.payload
			})
			.addCase(sendContactForm.rejected, (state) => {
				state.loader.sendingContactData = false
			})
	},
})

const contactReducer = contactSlice.reducer
export default contactReducer
