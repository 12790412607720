import React from "react";

function Customers() {
  return (
    <>
      <div className="customers">
        <div className="customers__list">
          <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089523/Crazy%20Tech/nokia_hffamn.svg" alt="Nokia" width={156} height={26} />
          <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089525/Crazy%20Tech/Group_iavpqm.svg" alt="Group On" width={160} height={29} />
          <img
            src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089523/Crazy%20Tech/honeywell_ayru19.svg"
            alt="Honey Well"
            width={181.67}
            height={35}
          />
          <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089529/Crazy%20Tech/abb_wj2ldt.svg" alt="ABB" width={86.67} height={35} />
          <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089527/Crazy%20Tech/barclays_sbj3x6.svg" alt="Bar Clays" width={195} height={35} />
        </div>
      </div>
    </>
  );
}

export default Customers;
