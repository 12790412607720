import React, {useEffect} from 'react'
import AOS from "aos";


function Projects({image, alt, title, description, link}) {
    useEffect(() => {
        AOS.init();
    }, [])


    return (
    <>
        {/*<a href={"/https::/www.google.com"}>*/}
        <div className='card' style={{
            background: "transparent",
            textAlign: "center",
        }} data-aos="fade-right" data-aos-duration="900">
            <div className='card-image'>
                <img className={"object-fit-cover"} src={image} alt={alt} />
            </div>
            <div className='card-content'>
                <div className='content-section'>
                    <div className='section-text'>
                        <div className='text-title' >
                            <a className="text-black bg-transparent"   href={link} target="_blank" rel="noopener noreferrer">{title}</a>
                        </div>
                        <div className='text-dsc'>{description}</div>
                    </div>
                </div>
            </div>
        </div>
        {/*</a>*/}
    </>
  )
}

export default Projects
