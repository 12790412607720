import React from "react";
import Project from "../Projects";
import { Link } from "react-router-dom";
import city17 from "../../images/Crazy Tech Ai Portfolios/city17.png";
import tigerIt from "../../images/tiger it 2.png";
import idnNetwork from "../../images/Crazy Tech Ai Portfolios/idnNetwork.png";
import idnCorporate from "../../images/Crazy Tech Ai Portfolios/idnCorporate.png";

function OurProjects() {
  return (
    <>
      <section className="projects">
        <div className="projects-section">
          <div className="section-content">
            <div className="content-title">Our Projects at Crazy Tech</div>
            <div className="content-dsc">
              Explore Our ventures into emerging technologies
            </div>
          </div>

          <div className="section-card">
            <Project
                image={city17}
                // image='https://res.cloudinary.com/de90d6t6e/image/upload/v1720616466/Crazy%20Tech%20AI/Portfolios/nsztmw6fejrd26szrbvm.png'
                alt='City 17 Display Manager'
                title='City 17 Display Manager'
                link="https://city17.betesla.com/"
                // description='An exploration of a Job finder landing page called HORIZOB. This helps users to search for jobs.'
            />
            <Project
                // image='https://res.cloudinary.com/dqg52thyo/image/upload/v1701089522/Crazy%20Tech/Tappa_y47c7a.png'
                image={tigerIt}
                alt='Tiger it'
                title='Tiger it'
                link="https://tigerit.app/buyer"
                // description='In the realm of startups and scaling companies, the trifecta of brand identity, a promotional website, and a seamless digital product.'
            />


            <Project
                // image='https://res.cloudinary.com/dqg52thyo/image/upload/v1701082039/Crazy%20Tech/Mockup_2-01_gdhnmw.jpg'
                image={idnNetwork}
                alt='IDN Network'
                title='IDN Network'
                link="https://book.betesla.com/"
                // description='Where Dreams Find a Home. Elevate Your Living Experience with Seamless Rentals. Discover, Connect, and Secure Your Perfect Space – Because Your Home Should Feel Like You, Always.'
            />
            <Project
                image={idnCorporate}
                alt='IDN Corporate'
                title='IDN Corporate'
                link="https://play.google.com/store/apps/details?id=com.betesla.idn_corporate"
                // description="Starly Live now to embark on a journey of live entertainment, creativity, and connection. Whether you're behind the camera or in the audience, Starly Live is where the stars come to life!"
            />
            {/*<Project*/}
            {/*    image='https://res.cloudinary.com/dqg52thyo/image/upload/v1701089523/Crazy%20Tech/Horizob_a67zk4.png'*/}
            {/*    alt='Horizob - Job Finder'*/}
            {/*    title='HORIZOB - Job Finder Landing Page Exploration'*/}
            {/*    description='An exploration of a Job finder landing page called HORIZOB. This helps users to search for jobs.'*/}
            {/*/>*/}
            {/*<Project*/}
            {/*    image='https://res.cloudinary.com/dqg52thyo/image/upload/v1701089522/Crazy%20Tech/Tappa_y47c7a.png'*/}
            {/*    alt='Tappa - Website Design'*/}
            {/*    title='Tappa! - Website design messenger application'*/}
            {/*    description='In the realm of startups and scaling companies, the trifecta of brand identity, a promotional website, and a seamless digital product.'*/}
            {/*/>*/}


            {/*<Project */}
            {/*    image='https://res.cloudinary.com/dqg52thyo/image/upload/v1701082039/Crazy%20Tech/Mockup_2-01_gdhnmw.jpg'*/}
            {/*    alt='Flat Mate'*/}
            {/*    title='FLATMATE'*/}
            {/*    description='Where Dreams Find a Home. Elevate Your Living Experience with Seamless Rentals. Discover, Connect, and Secure Your Perfect Space – Because Your Home Should Feel Like You, Always.'*/}
            {/*/>*/}
            {/*<Project */}
            {/*    image='https://res.cloudinary.com/dqg52thyo/image/upload/v1700994554/Crazy%20Tech/Starly_mockup_3-01_ynj7zp.jpg'*/}
            {/*    alt='STARLY - Live Stream App'*/}
            {/*    title='STARLY - Live Stream App'*/}
            {/*    description="Starly Live now to embark on a journey of live entertainment, creativity, and connection. Whether you're behind the camera or in the audience, Starly Live is where the stars come to life!"*/}
            {/*/>*/}
          </div>
          <div className="section-btn">
          <Link to="/projects" className="text-decoration-none">
            <button type="button" className="btn-button">
              <div className="button-title">View All Projects</div>
            </button>
          </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurProjects;
