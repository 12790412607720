import Project from "./Projects";
import tigerIt from "../images/tiger it 2.png"
// import Pagination from "./Pagination/Pagination";
import city17 from "../images/Crazy Tech Ai Portfolios/city17.png"
import idnNetwork from "../images/Crazy Tech Ai Portfolios/idnNetwork.png"
import idnCorporate from "../images/Crazy Tech Ai Portfolios/idnCorporate.png"
import leadsuite from "../images/Crazy Tech Ai Portfolios/leadsuite.png"
import crazybyrasel from  "../images/Crazy Tech Ai Portfolios/crazybyrasel.png"
import crazyness from  "../images/Crazy Tech Ai Portfolios/crazyness.png"
// import idnPrivate from "../images/Crazy Tech Ai Portfolios/IdnPrivate.png"
import directdrive from "../images/Crazy Tech Ai Portfolios/directDrive.png"


function OurProjectsDetail() {
    return (
        <div className="projects" >
            <div className="projects-section" >
                <div className="section-content"  style={
                    {
                        paddingTop: "30px"
                    }
                }>
                    <div className="content-title">Our Projects at Crazy Tech</div>
                    <div className="content-dsc">
                        Explore our ventures into emerging Technologies
                    </div>
                </div>

                <div className="section-card" >
                    <Project 
                        image={city17}
                        // image='https://res.cloudinary.com/de90d6t6e/image/upload/v1720616466/Crazy%20Tech%20AI/Portfolios/nsztmw6fejrd26szrbvm.png'
                        alt='City 17 Display Manager'
                        title='City 17 Display Manager'
                        link="https://city17.betesla.com/"
                        // description='An exploration of a Job finder landing page called HORIZOB. This helps users to search for jobs.'
                    />
                    <Project 
                        // image='https://res.cloudinary.com/dqg52thyo/image/upload/v1701089522/Crazy%20Tech/Tappa_y47c7a.png'
                        image={tigerIt}
                        alt='Tiger it'
                        title='Tiger it'
                        link="https://tigerit.app/buyer"
                        // description='In the realm of startups and scaling companies, the trifecta of brand identity, a promotional website, and a seamless digital product.'
                    />


                    <Project 
                        // image='https://res.cloudinary.com/dqg52thyo/image/upload/v1701082039/Crazy%20Tech/Mockup_2-01_gdhnmw.jpg'
                        image={idnNetwork}
                        alt='IDN Network'
                        title='IDN Network'
                        link="https://book.betesla.com/"
                        // description='Where Dreams Find a Home. Elevate Your Living Experience with Seamless Rentals. Discover, Connect, and Secure Your Perfect Space – Because Your Home Should Feel Like You, Always.'
                    />
                    <Project 
                        image={idnCorporate}
                        alt='IDN Corporate'
                        title='IDN Corporate'
                        link="https://play.google.com/store/apps/details?id=com.betesla.idn_corporate"
                        // description="Starly Live now to embark on a journey of live entertainment, creativity, and connection. Whether you're behind the camera or in the audience, Starly Live is where the stars come to life!"
                    />
                    {/*<Project */}
                    {/*    image='https://res.cloudinary.com/dqg52thyo/image/upload/v1700994554/Crazy%20Tech/Starly_mockup_3-01_ynj7zp.jpg'*/}
                    {/*    alt='STARLY - Live Stream App'*/}
                    {/*    title='STARLY - Live Stream App'*/}
                    {/*    description="Starly Live now to embark on a journey of live entertainment, creativity, and connection. Whether you're behind the camera or in the audience, Starly Live is where the stars come to life!"*/}
                    {/*/>*/}


                    <Project 
                        image={leadsuite}
                        alt='Lead Suite 360'
                        title='Lead Suite 360'
                        // description='UI8 is a marketplace curated by top industry leaders to bring you the best resources and solutions for your design process.'
                    />
                    <Project 
                        // image='https://res.cloudinary.com/de90d6t6e/image/upload/v1720616467/Crazy%20Tech%20AI/Portfolios/lpk61ntvz9qd9zuyvgzz.png'
                        image={crazybyrasel}
                        alt='Crazy By Rasel'
                        title='Crazy By Rasel'
                        link="https://crazybyrasel.com/"
                        // description='UI8 is a marketplace curated by top industry leaders to bring you the best resources and solutions for your design process.'
                    />


                    <Project 
                        // image='https://res.cloudinary.com/de90d6t6e/image/upload/v1720616468/Crazy%20Tech%20AI/Portfolios/y4tvx7h04t3exnjkjebi.png'
                        image={directdrive}
                        alt='Direct Drive'
                        title='Direct Drive'
                        link="https://play.google.com/store/apps/details?id=com.leadsoftwares.direct_drive"
                        // description='UI8 is a marketplace curated by top industry leaders to bring you the best resources and solutions for your design process.'
                    />
                    <Project 
                        // image='https://res.cloudinary.com/de90d6t6e/image/upload/v1720616468/Crazy%20Tech%20AI/Portfolios/apgh9uymrr8dglnkq6hs.png'
                        image={crazyness}
                        alt='Crazyness E-commerce'
                        title='Crazyness E-commerce'
                        link="https://crazybyrasel.com/buyer"
                        // description="UI8 is a marketplace curated by top industry leaders to bring you the best resources and solutions for your design process."
                    />
                </div>

                {/* <Pagination /> */}
            </div>
        </div>
    );
}

export default OurProjectsDetail;