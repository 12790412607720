// import * as React from "react";

const UpdateCard = ({ image, title, description, authorImage, autherName }) => {
  return (
    <>
      <div
        className="div"
        style={{
          cursor: "pointer",
        }}
      >
        <div
          className="div-2"
          style={{
            transition: ".2 ease-in-out",
            borderRadius: "10px",
          }}
        >
          <div className="div-3">
            <img
              loading="lazy"
              src={image}
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
              className="img"
              alt="account"
            />
            <div className="div-4">Software Development</div>
          </div>
        </div>
        <div>

        <div className="div-5">{title}</div>
        <div className="div-6">{description}</div>
        </div>
        <div
          className="div-7"
          style={{
            marginTop: "30px",
          }}
        >
          <div className="div-10">
            <img
              src={authorImage}
              alt={"user"}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              className="w-14 h-14 rounded-full object-cover"
            />
            <div className="div-11">{autherName}</div>
          </div>
          <div
            className="div-8"
            style={{
              alignItems: "center",
              gap: "5px"
            }}
          >
            <div className="div-9">Dec 4, 2023</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/57c8557cb615136d620122a759eeb2a9fa73d97ea93c64b034d63e142b0e72a9?"
              className="img-2"
              alt="calender"
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .div {
          align-items: flex-start;
          display: flex;
          max-width: 411px;
          padding-bottom: 24px;
          flex-direction: column;
          border-radius: 12px;
          transition: transform 0.3s ease-in-out;
        }
        .div:hover {
          transform: scale(1.1);
        }
        .div-2 {
          align-self: stretch;
          border-radius: 8px;
          background-color: #fff;
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
        }
        .div-3 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          position: relative;
          display: flex;
          aspect-ratio: 1.644;
          width: 100%;
          align-items: start;
          padding: 16px 60px 50px 16px;
        }
        .img {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .div-4 {
          position: absolute;
          padding: 0.5rem;
          background: #c0ff00;
          border-radius: 8px;

          font-family: "Manrope";
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.075rem;
        }
        .div-5 {
          align-self: center;
          color: var(--Text-T---Black, #000);
          leading-trim: both;
          text-edge: cap;
          margin-top: 24px;
          padding-left: 20px;
          font: 700 20px/20px Manrope, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-6 {
          align-self: center;
          color: var(--Grays-G8, #474c59);
          margin-top: 12px;
          padding-left: 20px;
          font: 400 16px/22px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-7 {
          align-self: center;
          display: flex;
          margin-top: 16px;
          width: 100%;
          max-width: 379px;
          justify-content: space-between;
          gap: 20px;
        }
        .div-8 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px !important;
          padding: 0 20px;
          // background-color: green;
          text-wrap: nowrap;
        }
        .img-2 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .div-9 {
          color: var(--Grays-G8, #474c59);
          align-self: center;
          margin-top: 2px;
          font: 400 16px/136% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
            // background-color: orange;
    
        }
        .div-10 {
          display: flex;
          align-items: center !important;
          justify-content: space-between;
          gap: 8px;
          padding: 0 20px;
          // background-color:green
        }
        .img-3 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .div-11 {
          color: var(--Grays-G8, #474c59);
          // align-self: stretch;
          font: 400 16px/136% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
      `}</style>
    </>
  );
};

export default UpdateCard;
