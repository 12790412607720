import React from "react";
import { Link } from "react-router-dom";
import "./heroSection.css"

function HeroSection({heading, height, margin, subheadings , description , buttonDesc , check = false , checkTow = false , title2, parent_class}) {
  return (
    <>
      <div className={`heroSection content ${parent_class === "service" ? "service" : ""}`} style={{height: height, transform: "translateY(1000px)"}}>
        <div className="content__list" style={{margin: margin}}>
          <div className="content__items">
            <div className="content__titles">
              <span className="content__title-1">
                {heading}
                <br />
                {/* {check && } */}
              </span>
              <span className="content__title-2">{subheadings}</span>
              {checkTow && <span className="content__title-1">{title2}</span>}
            </div>
            <div className="content__description">
              {description}
            </div>
          </div>
          <div className="nav-btn">
          <Link to="/project/field" className="text-decoration-none">
            <button className="content__button">
              <span className="content__button--title">
                {buttonDesc}
              </span>
            </button>
          </Link>
          </div>
        </div>

        {/* scheck && (
        <>
        <div className="content__elements">
          <img
            src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089525/Crazy%20Tech/Elements_g3fohs.svg"
            alt="Element fill with dots"
            width={66}
            height={103}
          />
        </div>
        <div className="content__tag">
          <div className="content__tag--img">
            <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089523/Crazy%20Tech/Tag_sggtcx.svg" alt="HTML Close Tag" width={54} height={56} />
          </div>
        </div>
        <div className="content__bracket">
          <div className="content__bracket--img">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089527/Crazy%20Tech/Bracket_pzydup.svg"
              alt="Dots Inside Curly Bracket"
              width={98.2}
              height={67.8}
            />
          </div>
        </div>
        <div className="content__frame">
          <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089526/Crazy%20Tech/Frame_lgfbdh.svg" alt="Frame Tea Cup" width={73} height={101} />
        </div>
        <div className="content__ellipse">
          <img
            src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089524/Crazy%20Tech/Ellipse_gcpqqp.svg"
            alt="Ellipse look like D shape"
            width={101.21}
            height={93.04}
          />
        </div>
        <div className="content__layer">
          <img
            src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089522/Crazy%20Tech/Layer_lrzjpw.svg"
            alt="Layers of code"
            width={86.19}
            height={82.51}
          />
        </div>
        </> 
        ) */}
      </div>
    </>
  );
}

export default HeroSection;
