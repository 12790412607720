import './map.css'
import { GoogleMap, MarkerF } from "@react-google-maps/api"

function Map() {
    const center = {lat: 27.994402, lng: -81.760254}
  return (
    <>
      <GoogleMap zoom={8} center={center} mapContainerClassName='map-container' options={{
        zoomControl: true,
        streetViewControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      }}>
        <MarkerF position={center} icon={{
          url: '/address.png',
          scaledSize: new window.google.maps.Size(30, 30)
        }}/>
      </GoogleMap>
    </>
  )
}

export default Map
