import { useState } from "react";
import "./projectJourney.css";
import { Link } from "react-router-dom";

function ProjectJourney() {
  const [isChecked, setIsChecked] = useState("developer");
  const [type, setType] = useState("developer")
  return (
    <>
      <div className="project-journey row">
        <div className="col-lg-6 col-md-8 col-sm-10 offset-md-2 offset-sm-1 offset-lg-0">
          <div className="project-field">
            <h1>Start Your Project Journey</h1>
            <p>
              Unlock the Power of Exceptional Development Talent for Your Next
              Venture
            </p>
          </div>
          <div className="field-option">
            <label
              className={`form-check ${isChecked === "developer" && "active"}`}
              onClick={() => {
                setIsChecked("developer")
                setType("developer")
              }}
              for="flexRadioDefault1"
            >
              <input
                type="radio"
                className="form-check-input"
                name="project-field"
                id="flexRadioDefault1"
                checked={isChecked === "developer" && "true"}
              />
              <div class="form-check-label">
                <h3>Developer</h3>
                <p>Software Developers, Data scientists, DevOps, and QA</p>
              </div>
            </label>

            <label
              className={`form-check ${isChecked === "designer" && "active"}`}
              onClick={() => {
                setIsChecked("designer")
                setType("designers")
              }}
              for="flexRadioDefault2"
            >
              <input
                type="radio"
                className="form-check-input"
                name="project-field"
                id="flexRadioDefault2"
                checked={isChecked === "designer" && "true"}
              />
              <div class="form-check-label">
                <h3>Designers</h3>
                <p>Web Mobile, UI/UX, Branding, and Visual Designers</p>
              </div>
            </label>

            <label
              className={`form-check ${isChecked === "marketing" && "active"}`}
              onClick={() => {
                setIsChecked("marketing")
                setType("marketing experts")
              }}
              for="flexRadioDefault3"
            >
              <input
                type="radio"
                className="form-check-input"
                name="project-field"
                id="flexRadioDefault3"
                checked={isChecked === "marketing" && "true"}
              />
              <div class="form-check-label">
                <h3>Marketing Experts</h3>
                <p>
                  Growth Marketing Expert, Content Marketing Strategist, Digital
                  Markerts
                </p>
              </div>
            </label>

            <label
              className={`form-check ${isChecked === "manager" && "active"}`}
              onClick={() => {
                setIsChecked("manager")
                setType("project managers")
              }}
              for="flexRadioDefault5"
            >
              <input
                type="radio"
                className="form-check-input"
                name="project-field"
                id="flexRadioDefault5"
                checked={isChecked === "manager" && "true"}
              />
              <div class="form-check-label">
                <h3>Project Managers</h3>
                <p>
                  Digital Product managers, Product Owners, and Business
                  Analysts
                </p>
              </div>
            </label>

            <label
              className={`form-check ${isChecked === "finance" && "active"}`}
              onClick={() => {
                setIsChecked("finance")
                setType("finance experts")
              }}
              for="flexRadioDefault6"
            >
              <input
                type="radio"
                className="form-check-input"
                name="project-field"
                id="flexRadioDefault6"
                checked={isChecked === "finance" && "true"}
              />
              <div class="form-check-label">
                <h3>Finance Experts</h3>
                <p>
                  Financial Modelers, Fundraising Advisors, M&A and FP&A Experts
                </p>
              </div>
            </label>
          </div>
          <Link
            to={`/project/details?type=${type}`}
            className="text-decoration-none project-journey-button"
          >
            <button type="button" className="continue">
              Continue
            </button>
          </Link>
        </div>
        <div className="col-lg-5 col-md-8 col-sm-10 offset-md-2 offset-sm-1 offset-lg-0">
          <img
            src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703335121/innovate-us_pgi8is.svg"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default ProjectJourney;
