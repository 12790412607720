import { useEffect, useState } from "react";
import ProjectMessage from "../ProjectMessage/ProjectMessage";
import "./projectDetails.css";

function ProjectDetails() {
  const initialState = {
    email: "",
    service: "web app development",
    other: "",
    budget: "",
    skills: [],
    additional: "",
    success: false
  }

  const [isActiveService, setISActiveService] = useState(false)
  const [addProject, setAddProject] = useState(initialState)

  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get("type")

  useEffect(() => {
    if(addProject.service === "others" ) setISActiveService(true)
    else setISActiveService(false)
  }, [addProject.service])

  useEffect(() => {
    const uniqueSkills = Array.from(new Set(addProject.skills));

    setAddProject({...addProject, skills: uniqueSkills});
  }, [addProject]);

  const removeSkill = (skillToRemove) => {
    const updatedSkills = addProject.skills.filter(skill => skill !== skillToRemove);
    setAddProject({...addProject, skills: updatedSkills});
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setAddProject({...addProject, [name]: value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(!addProject.service || !addProject.skills || !addProject.additional || !addProject.budget) return console.log("error: Fill all the fields")

      const response = await fetch('https://us-central1-crazy-tech-51ecd.cloudfunctions.net/api/v1/quote', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          projectCategory: type,
          projectService: addProject.other.length > 1 && addProject.service === 'others' ? addProject.other : addProject.service,
          email: addProject.email,
          budget: addProject.budget,
          description: addProject.additional,
          otherSkills: addProject.skills
        })
      })

      const result = await response.json()
      if(result.success) setAddProject({...addProject, success: true})
    } catch(error) {
      console.error(error.message)
    }
  }

  return (
    <>
      <div className="project-details row">
        <div className="col-lg-6 col-md-8 col-sm-10 offset-md-2 offset-sm-1 offset-lg-0">
          <div className="project-field">
            <h1>Start Your Project Journey</h1>
            <p>
              Unlock the Power of Exceptional Development Talent for Your Next
              Venture
            </p>
          </div>

          <form className="field-option">
            <div class="mb-3">
              <label for="exampleSelectOption2" class="form-label">
                Email
              </label>
              <input type="email" className="form-control" name="email" placeholder="Enter your email..." onChange={handleChange} required/>
            </div>
            <div class="mb-3">
              <label for="exampleSelectOption3" class="form-label">
                Service
              </label>
              <select
                class="form-select"
                id="exampleSelectOption3"
                aria-label="Default select example"
                name="service"
                onChange={handleChange}
              >
                <option selected value="web app development">Web App Development</option>
                <option value="mobile app development">Mobile App Development</option>
                <option value="ui/ux design">UI/UX Design</option>
                <option value="qa & testing">QA & Testing</option>
                <option value="e-commerce solutions">E-commerce Solutions</option>
                <option value="api's development & integration">API's Development & Integration</option>
                <option value="maintenance & support">Maintenance & Support</option>
                <option value="others">Others</option>
              </select>
            </div>
            {isActiveService && 
              <div className="mb-3">
                <input type="text" className="form-control" name="other" placeholder="Enter service you want to connect with" onChange={handleChange}/>
              </div>
            }
            <div class="mb-3">
              <label for="exampleSelectOption2" class="form-label">
                Budget
              </label>
              <input type="text" className="form-control" name="budget" placeholder="What is your budget?" onChange={handleChange} required/>
            </div>
            <div class="mb-3">
              <label for="exampleSelectOption1" class="form-label">
                Other Skills
              </label>
              <select
                class="form-select"
                id="exampleSelectOption1"
                aria-label="Default select example"
                onChange={(e) => setAddProject({...addProject, skills: [...addProject.skills, e.target.value]})}
              >
                <option selected value="flutter">
                  Flutter
                </option>
                <option value="mern">MERN</option>
                <option value="next js">Next JS</option>
                <option value="html 5">HTML 5</option>
                <option value="django">Django</option>
              </select>
            </div>
            <div className="mb-3 row">
              {addProject.skills && addProject.skills.map((skill, index) => (
                <div className="skill col-lg-4" key={index}>
                  <p>{skill.toUpperCase()}</p>
                  <img
                    src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703427817/Crazy%20Tech/cancel_qrtr0o.svg"
                    alt=""
                    style={{cursor: 'pointer'}}
                    onClick={() => removeSkill(skill)}
                  />
                </div>
              ))}
              </div>

            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Additional
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                name="additional"
                rows="5"
                placeholder="Type something..."
                onChange={handleChange}
              ></textarea>
            </div>
          </form>

          <button type="submit" className="continue" onClick={handleSubmit}>
            Continue
          </button>
        </div>
        <div className="col-lg-5 col-md-8 col-sm-10 offset-md-2 offset-sm-1 offset-lg-0">
          <img
            src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703335121/innovate-us_pgi8is.svg"
            alt=""
          />
        </div>
      </div>

      {addProject.success && <ProjectMessage setAddProject={setAddProject} addProject={addProject}/>}
    </>
  );
}

export default ProjectDetails;
