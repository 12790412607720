// import React from "react";
import Slider from "react-slick";
import { IconQuote } from "@tabler/icons-react";
import client3 from "../../images/png/review 3.png";
import girlImage from "../../images/png/review 1.png";
import client2 from "../../images/png/review 2.png";
import "./testimonial.css";

const testimonials = [
  {
    quote:
      "Expertise made all the difference, absolute pleasure to work with. Exceeded our expectations.",
    author: "Stella Smith",
    role: "Client",
    image: "https://randomuser.me/api/portraits/women/12.jpg",
  },
  {
    quote:
      "I highly recommend this agency. Testing for our project was done everything top-notch.",
    author: "Sam Martinez",
    role: "Designer at Converta",
    image: "https://randomuser.me/api/portraits/men/22.jpg",
  },
  {
    quote:
      "Expertise made all the difference, absolute pleasure to work with. Exceeded our expectations.",
    author: "Alex Johnson",
    role: "Engineer at Unify",
    image: client3,
  },
  {
    quote:
      "I highly recommend this agency. Testing for our project was done everything top-notch.",
    author: "Jordan Wilson",
    role: "Designer at Converta",
    image: client2,
  },
  {
    quote:
      "Expertise made all the difference, absolute pleasure to work with. Exceeded our expectations.",
    author: "Morgan Davis",
    role: "Engineer at Unify",
    image: girlImage,
  },
  {
    quote:
      "Expertise made all the difference, absolute pleasure to work with. Exceeded our expectations.",
    author: "Riley Anderson",
    role: "Engineer at Unify",
    image: "https://randomuser.me/api/portraits/women/23.jpg",
  },
];

const TestimonialCard = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    defaultSlide: 0,
    autoplay: true,
    loop: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          defaultSlide: 1,
          initialSlide: 3,
        },
      },
    ],
  };

  const cards = testimonials.map((card, index) => (
    <div className="slider_Card"
    >
      <div
        key={index}
        className="reviewCards  transition-cards ease-in-out delay-150 hover:scale-110 cursor-pointer flex items-center justify-center"
      >
        <div
          className="space-y-6 bg-[#F8FAFC] h-full rounded-lg p-6"
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
            padding: "20px",
            height: "200px",
            borderRadius: "10px",
          }}
        >
          <div className="flex items-center justify-start gap-10  ">
            <span className="p-1 rounded-full bg-blue-300">
              <IconQuote />
            </span>
            <p className="h-[2px] w-[100%] rounded bg-gray-200"></p>
          </div>
          <p className="multi-line-text-truncate" style={{ color: "black" }}>
            {card.quote}
          </p>
          <div
            className="flex items-center mb-4"
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <img
              src={card.image}
              alt={card.author}
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              className="w-14 h-14 rounded-full object-cover"
            />
            <div className="ml-4">
              <h4
                className="text-lg font-bold"
                style={{
                  fontSize: "18px",
                }}
              >
                {card.author}
              </h4>
              {/*<p className="text-sm text-gray-500">{card.role}</p>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <div className="my-10" style={{
        // background: "green",
        padding: "30px 0"
      }}>
        <div className="testimonial-title mt-4 text-center">
          <h1 className="title ">What Our Clients Say</h1>
          <p className="">
            Discover the experiences and success stories shared by our valued
            clients.
          </p>
        </div>
        <div
          className="slider-container cursor-grab"
          style={{
            gap: "10px",
          }}
        >
          <Slider {...settings}>{cards}</Slider>
        </div>
      </div>
    </>
  );
};
export default TestimonialCard;
