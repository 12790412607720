import { useRef } from "react";
import "./projectMessage.css";

function ProjectMessage({setAddProject, addProject}) {
  const message = useRef();

  // useEffect(() => {
  //     document.addEventListener('mousedown', clickOutsideMessage)
  // }, [addProject.success])
  //
  // const clickOutsideMessage = (e) => {
  //   if(message.current && !message.current.contains(e.target) && addProject.success) {
  //     setAddProject({...addProject, success: false})
  //   }
  // }

  return (
    <>
      <div className="project-message">
        <div ref={message} className="message">
          <img
            src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703429099/Crazy%20Tech/check_circle_outline_pbx1bk.svg"
            alt=""
          />
          <div className="message-text">
          <h1>Project Details Submitted Successfully</h1>
          <p>
            Thank you for providing the essential details for your project! Our
            team is eager to review your requirements and will reach out to you
            shortly.
          </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectMessage;
