import { useLoadScript } from '@react-google-maps/api'
import AOS from 'aos'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { sendContactForm } from '../../store/contact/contactAction'
import Map from '../GoogleMap/Map'
import ProjectMessage from '../ProjectMessage/ProjectMessage'
import './contact.css'

function Contact() {
	const dispatch = useDispatch()
	// const {  name, email, phone, message } = useSelector((state) => state.contact);

	const initialState = {
		name: '',
		email: '',
		phoneNumber: '',
		message: '',
		service: 'mobie app development',
	}

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: 'AIzaSyBMKFARzpDlpUzF3lOls3ExQ_kaesoMIR0',
		libraries: ['places'],
	})

	const [state, setState] = useState(initialState)
	const [errors, setErrors] = useState({})
	const notify = () =>
		toast.success("Thanks for contacting us!. We'll get back to you soon.", {
			// position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		})

	const errornotify = () =>
		toast.error('Something went wrong!!!', {
			// position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		})

	useEffect(() => {
		AOS.init()
	}, [])

	const validateField = (name, value) => {
		let error = ''
		switch (name) {
			case 'name':
				if (!value) error = 'Please enter your name.'
				break
			case 'email':
				if (!value) error = 'Please enter a valid email.'
				else {
					const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
					if (!emailPattern.test(value))
						error = 'Please enter a valid email address.'
				}
				break
			case 'phoneNumber':
				if (!value) error = 'Please enter your phone number.'
				break
			case 'organization':
				if (!value) error = 'Please enter your organization.'
				break
			case 'message':
				if (!value) error = 'Please enter a message.'
				break
			default:
				break
		}
		return error
	}

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target
		setState({
			...state,
			[name]: type === 'checkbox' ? checked : value,
		})

		// Validate the current field
		const error = validateField(name, type === 'checkbox' ? checked : value)
		setErrors({
			...errors,
			[name]: error,
		})
	}

	const validate = () => {
		const newErrors = {}
		for (const field in state) {
			if (field !== 'check') {
				const error = validateField(field, state[field])
				if (error) newErrors[field] = error
			}
		}
		return newErrors
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		const newErrors = validate()
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors)
		} else {
			setErrors({})
			// Submit form logic here
			console.log('Form submitted', state)
		}

		try {
			if (!state.name || !state.email || !state.phoneNumber || !state.message)
				return console.log('error: Fill all the fields')
			// if (!state.check)
			//   return console.log("Please accept the terms and conditions");

			// const response = await fetch(
			//   "https://us-central1-crazy-tech-51ecd.cloudfunctions.net/helloWorld",
			//   {
			//     method: "POST",
			//     headers: {
			//       "Content-Type": "application/json",
			//     },
			//     body: JSON.stringify({
			//       name: state.name,
			//       email: state.email,
			//       phone: state.phone,
			//       message: state.message,
			//       helpType: state.helpType,
			//       organization: state.organization,
			//     }),
			//   }
			// );

			// const result = await response.json();
			// console.log(result);

			// if (result.success) setState({ ...state, success: true });

			dispatch(sendContactForm(state)).then(() => {
				notify()
				setState(initialState)
			})
		} catch (error) {
			console.error('Error Message', error.message)
			errornotify()
		}
	}
	return (
		<>
			{/* <div className='row contact-hero-section'>
        <div className='col-lg-6 col-md-12 contact-us-content'>
          <h1 className='contact-hero-section-title'>Ready To Get Started?</h1>
          <p className='contact-hero-section-description'>Have inquiries or wish to discuss your project? Reach out to us using the provided contact details or simply fill out the form below to connect with us directly.</p>
        </div>
        <div className='col-lg-6 col-md-12 contact-us-image'>
          <div className='contact-us-rectangle'>
            <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702628387/Rectangle_1553_azkpfh.png" alt="" />
          </div>

          <div className='contact-us-phone'>
            <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702628384/local_phone_wf9bdb.svg" alt="" />
          </div>

          <div className='contact-us-elements'>
            <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702628385/Group_18499_wtaqfi.svg" alt="" />
          </div>

          <div className='contact-us-vector'>
            <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702628413/Vector_ogkwmw.svg" alt="" />
          </div>
        </div>
  </div> */}

			<ToastContainer
				position='bottom-right'
				style={{ padding: '10px', marginBottom: '5px' }}
			/>

			<div className='contact-hero-section'>
				<div className='contact-title'>
					<h1>Our Contact Information</h1>
					<p>Engage in conversation with skilled engineers.</p>
				</div>

				<div className='contact-annoucement row'>
					<a
						data-aos='fade-right'
						data-aos-duration='1100'
						href='https://maps.app.goo.gl/8eXhPUKjAFoqQEeW7'
						target='_blank'
						rel='noreferrer'
						className='col-lg-3 col-md-6 col-sm-12 text-decoration-none text-black'
					>
						<div
							className=''
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<img
								src='https://res.cloudinary.com/dqg52thyo/image/upload/v1703579757/location_uwdeti.png'
								alt=''
								width={45}
								height={45}
							/>
							<div className='annoucement-alert'>
								<h2 className='text-center'>Our Location</h2>
								{/* <div className="annoucement-paragraph">
                414 Gallimore Dairy Rd Greensboro, NC 27409
              </div> */}
								<div className='annoucement-paragraph'>
									<a
										href='https://maps.app.goo.gl/8eXhPUKjAFoqQEeW7'
										target='_blank'
										rel='noreferrer'
										className='text-decoration-none text-black'
									>
										Florida, USA
									</a>{' '}
								</div>
								{/* <div className="annoucement-paragraph">
                Street 14 Zakariya Town, Multan
              </div> */}
							</div>
						</div>
					</a>

					<a
						data-aos='fade-up'
						data-aos-duration='1100'
						href='tel:+17869494477'
						className='col-lg-3 col-md-6 col-sm-12 text-decoration-none text-black'
					>
						<div
							className=''
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<img
								src='https://res.cloudinary.com/dqg52thyo/image/upload/v1703579757/telephone_fgafxs.png'
								alt=''
								width={40}
								height={40}
							/>
							<div className='annoucement-alert'>
								<h2>Give us a call</h2>
								<div className='annoucement-paragraph'>
									<a
										href='tel:+17969384477'
										className='text-decoration-none text-black'
									>
										<p>(+1) 796 938 4477</p>
									</a>
									{/* <a href="tel:+923030775915" className="text-decoration-none text-black"><p>(+92) 320 0600224</p></a> */}
								</div>
							</div>
						</div>
					</a>

					<a
						data-aos='fade-up'
						data-aos-duration='1100'
						href='mailto:contact@crazytech.ai'
						className='col-lg-3 col-md-6 col-sm-12 text-decoration-none text-black'
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<img
								src='https://res.cloudinary.com/dqg52thyo/image/upload/v1703326100/announcement_hjxyet.svg'
								alt=''
								width={45}
								height={45}
							/>
							<div className='annoucement-alert'>
								<h2>Chat with us</h2>
								<div className='annoucement-paragraph'>
									<a
										href='mailto:contact@crazytech.ai'
										className='text-decoration-none text-black'
									>
										<p>contact@crazytech.ai</p>
									</a>
									{/* <a href="mailto:contact@crazytech.ai" className="text-decoration-none text-black"><p>muhamadkhuram207@gmail.com</p></a> */}
								</div>
							</div>
						</div>
					</a>
				</div>

				<Link to='/project/field' className='text-decoration-none'>
					<button type='button' className='start-project'>
						Start A Project
					</button>
				</Link>
			</div>

			<div className='row contact-us-form'>
				<div className='col-xl-6 col-lg-6 col-md-12'>
					<form onSubmit={handleSubmit} className='needs-validation' noValidate>
						<div className='mb-3'>
							<label for='exampleInputPassword1' class='form-label'>
								Your Name
							</label>
							<input
								type='text'
								name='name'
								value={state.name}
								className={`form-control ${errors.name ? 'is-invalid' : ''}`}
								id='exampleInputPassword1'
								onChange={handleChange}
							/>
							{errors.name && (
								<div className='invalid-feedback'>{errors.name}</div>
							)}
						</div>
						<div class='row mb-3'>
							<div className='col-lg-6 col-md-12 mb-3'>
								<label for='exampleInputPassword2' class='form-label'>
									Your Email
								</label>
								<input
									type='email'
									name='email'
									value={state.email}
									className={`form-control ${errors.email ? 'is-invalid' : ''}`}
									id='exampleInputPassword2'
									onChange={handleChange}
								/>
								{errors.email && (
									<div className='invalid-feedback'>{errors.email}</div>
								)}
							</div>
							<div class='col-lg-6 col-md-12'>
								<label
									for='exampleInputEmail1'
									class='form-label'
									style={{ marginLeft: '5px' }}
								>
									How can we help you
								</label>
								<select
									class='form-select'
									name='helpType'
									value={state.helpType}
									aria-label='>>> Select <<<'
									onChange={handleChange}
								>
									<option value='mobie app development' selected>
										Mobile App Development
									</option>
									<option value='web app development'>
										Web App Development
									</option>
									<option value='graphic design'>Graphic Design</option>
									<option value='custom software development'>
										Custom Software Development
									</option>
									<option value='cloud / devops development'>
										Cloud / DevOps Development
									</option>
									<option value='software qa & testing'>
										Software QA & Testing
									</option>
								</select>
							</div>
						</div>
						<div class='row mb-3'>
							<div className='col-lg-6 col-md-12 mb-3'>
								<label for='exampleInputPassword4' class='form-label'>
									Mobile
								</label>
								<input
									type='tel'
									name='phoneNumber'
									value={state.phoneNumber}
									className={`form-control ${
										errors.phoneNumber ? 'is-invalid' : ''
									}`}
									id='exampleInputPassword4'
									onChange={handleChange}
								/>
								{errors.phoneNumber && (
									<div className='invalid-feedback'>{errors.phoneNumber}</div>
								)}
							</div>
							<div className='col-lg-6 col-md-12'>
								<label for='exampleInputPassword5' class='form-label'>
									Organization
								</label>
								<input
									type='text'
									name='organization'
									value={state.organization}
									class='form-control'
									id='exampleInputPassword5'
									onChange={handleChange}
								/>
							</div>
						</div>
						<div class='mb-3'>
							<label for='exampleFormControlTextarea1' class='form-label'>
								Message
							</label>
							<textarea
								className={`form-control ${errors.message ? 'is-invalid' : ''}`}
								name='message'
								value={state.message}
								id='exampleFormControlTextarea1'
								rows='5'
								onChange={handleChange}
							></textarea>

							{errors.message && (
								<div className='invalid-feedback'>{errors.message}</div>
							)}
						</div>
						<div class='mb-3 form-check'>
							<input
								type='checkbox'
								name='check'
								class='form-check-input'
								id='exampleCheck1'
								checked={state.check}
								onChange={handleChange}
							/>
							<label class='form-check-label' for='exampleCheck1'>
								Click here to subscribe for updates.
							</label>
						</div>
						<div className='send-message'>
							<button type='submit' class='form-button'>
								Send Message
							</button>
						</div>
					</form>
				</div>
				<div className='col-xl-6 col-lg-6 col-md-12 contact-google-map'>
					{/* Map */}
					{!isLoaded ? (
						<div className='loader'>
							<div class='spinner-border text-success' role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</div>
					) : (
						<Map />
					)}
				</div>
			</div>

			{state.success && (
				<ProjectMessage setAddProject={setState} addProject={state} />
			)}
		</>
	)
}

export default Contact
