import axios from 'axios'

const axiosInstance = axios.create({
	baseURL: 'https://crazytechwebserver-production.up.railway.app/api/v1',
	// baseURL: 'https://192.168.1.7:3000/api/v1',
	// baseURL: 'https://test-crazybyrasel-production.up.railway.app/api/v1',
	headers: {
		Accept: 'application/json',
	},
})

export default axiosInstance
