import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../config/axiosConfig'

export const sendContactForm = createAsyncThunk(
	'contact/sendContactForm',
	async (data) => {
		try {
			const response = await axiosInstance.post('/public/contact', data)
			return response.data
		} catch (error) {
			console.log(error)
		}
	}
)
