import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Services from "./components/Services";
import OurProjectsDetail from "./components/OurProjectsDetail";
import Blog from "./components/Blog";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import ProjectJourney from "./components/ProjectJourney/ProjectJourney";
import ProjectDetails from "./components/ProjectDetails/ProjectDetails";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';



function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: '100vh' }}>
      <Router>
        <Navbar />
        <div style={{ flex: 1, position: "relative", width: "100%", overflow: "hidden" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<OurProjectsDetail />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/project/field" element={<ProjectJourney />} />
            <Route path="/project/details" element={<ProjectDetails />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
