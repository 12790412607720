import { useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import logo from '../../images/logo light.png'
import './Footer.css'
// import { IconUser } from '@tabler/icons-react';

function Footer() {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return (
		<>
			<footer class='footer-section'>
				<div class='container-fluid'>
					<div class='footer-content pt-5 pb-5'>
						<div
							class='row justify-center'
							style={{
								justifyContent: 'center',
								gap: '10px',
								// background: "green"
							}}
						>
							<div class='col-xl-4 col-lg-5 col-md-3 mb-50 mb-4 mt-4 px-3'>
								<div class='footer-widget'>
									<div class='footer-logo'>
										<NavLink
											to='/'
											onClick={window.scrollTo({
												top: 0,
												behavior: 'smooth',
											})}
										>
											{' '}
											<img
												src={logo}
												style={{
													height: '30px',
													width: 'auto',
													marginLeft: '10px',
													cursor: 'pointer',
												}}
												alt='Crazy Tech Logo'
											/>
										</NavLink>
									</div>
									<div class='footer-text'>
										<p>
											Empowering positive change with innovative tech solutions,
											we elevate businesses beyond expectations.
										</p>
									</div>
									<div class='footer-social-icon mt-4'>
										<Link to=''>
											<i class='fab fa-facebook-f facebook-bg'></i>
										</Link>
										<Link to=''>
											<i class='fab fa-linkedin-in linkedin-in-bg'></i>
										</Link>
										<Link to='https://www.instagram.com/crazytechinnovation/'>
											<i class='fab fa-instagram instagram-bg'></i>
										</Link>
									</div>
								</div>
							</div>

							<div class='col-lg-2 col-md-3 col-4 footer-links mb-4 mt-4 px-0'>
								<p className='ms-4 footer-titles'>
									<span className='footer-main-titles'>ABOUT</span>
								</p>
								<ul
									style={{
										padding: '25px',
									}}
								>
									<li>
										<Link to='/'>Home</Link>
									</li>
									<li>
										<Link to='/services#'>Services</Link>
									</li>
									<li>
										<Link to='/projects'>Projects</Link>
									</li>
									<li>
										<Link to='/blog'>Blogs</Link>
									</li>
									<li>
										<Link to='/about'>About Us</Link>
									</li>
									<li>
										<Link to='/contact'>Contact Us</Link>
									</li>
								</ul>
							</div>
							{/* <div class="col-lg-3 col-md-3  col-6 footer-links mb-4 mt-4 px-0">
                <p className="ms-4 footer-titles">
                  <span className="footer-main-titles">OUR SERVICES</span>
                </p>
                <ul>
                  <li className="text-white">
                    Mobile App Development
                  </li>
                  <li className="text-white">
                    Web App Development
                  </li>
                  <li className="text-white">
                    SEO & Social Media Marketing
                  </li>
                  <li className="text-white">
                    Graphic Design
                  </li>
                  <li className="text-white">
                    Cloud / DevOps Development
                  </li>
                  <li className="text-white">
                    Software QA & Testing
                  </li>
                </ul>
              </div> */}
							<div class='col-xl-2 col-lg-3 col-md-3  col-7 footer-links mb-4 mt-4 px-0'>
								<p className='footer-titles ms-4'>
									<span className='footer-main-titles'>CONTACT US</span>
								</p>
								<ul>
									<li className='text-white'>
										{/* <a href="tel:+17869494477">
                        <i class="fa fa-phone me-2" aria-hidden="true"></i> (+1) 786 949 4477
                      </a> */}
										{/* <a href="tel:+17869494477">
                    <i class="fa-solid fa-user me-2"></i> Khuram Iftikhar
                    </a> */}
									</li>
									<li className='text-white'>
										<a href='tel:+17969384477' className='d-flex'>
											<i class='fa fa-phone me-2' aria-hidden='true'></i> (+1)
											796 938 4477
										</a>
										{/* <a href="tel:+17869494477">
                      <i class="fa fa-phone me-2" aria-hidden="true"></i> (+92) 320 0600224
                    </a> */}
									</li>
									<li className='text-white'>
										<a
											href='mailto:contact@crazytech.ai'
											className='text-lowercase'
										>
											<i class='fa-solid fa-envelope me-2'></i>
											contact@crazytech.ai
										</a>
										{/* <a href="mailto:contact@crazytech.ai" className="text-lowercase">
                      <i class="fa-solid fa-envelope me-2"></i>
                      muhamadkhuram207@gmail.com
                    </a> */}
									</li>
									<li className='text-white'>
										{' '}
										<i class='fa-solid fa-location-dot me-2'></i> Florida, USA
										{/* <i class="fa-solid fa-location-dot me-2"></i> Street 14 Zakariya Town, Multan */}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer
