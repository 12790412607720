import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./navbar.css";
import logo from "../../images/crazy tech white logo.png";




function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [active, setActive] = useState("");
  const { pathname } = useLocation();
  // const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);



  // window.addEventListener("click", function(e) {
  //   document.getElementById("navbarCollapse").h
  //  })

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    switch (pathname) {
      case "/":
        setActive("Home");
        break;
      case "/services":
        setActive("Services");
        break;
      case "/blog":
        setActive("Blog");
        break;
      case "/projects":
        setActive("Projects");
        break;
      case "/about":
        setActive("About Us");
        break;
      default:
        setActive("");
        break;
    }
  }, [pathname]);

  function menuRemove(e) {
    const menuBtn = document.querySelector(".navbar__menu--btn");
    const navList = document.querySelector(".navbar__list--area");

    setActive(e.target.innerText);

    if (showMenu) {
      menuBtn.classList.remove("open");
      navList.classList.remove("open");
      setShowMenu(false);
    }
  }

  return (
    <>
      <div
        className="navbar"
        style={{
          position: "sticky",
          top: "0%",
          zIndex: 10000,
        }}
      >
        <div className="navbar__title">
          <div className="navbar__title--text">
            <NavLink
              to="/"
              onClick={window.scrollTo({
                top: 0,
                behavior: "smooth",
              })}
            >
              {" "}
              <img
                src={logo}
                style={{
                  height: "30px",
                  width: "auto",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                alt="Crazy Tech Logo"
              />
            </NavLink>
          </div>
        </div>
        <div
          className="navbar__list--area"
          style={{
            // background: "blue",
            gap: "45px",
          }}
        >
          <div className="navbar__items">
            <div
              className={`navbar__link ${active === "Home" ? "active" : ""}`}
            >
              <NavLink to="/" onClick={menuRemove}>
                Home
              </NavLink>
            </div>
            <div
              className={`navbar__link ${
                active === "Services" ? "active" : ""
              }`}
            >
              <NavLink to="/services" onClick={menuRemove}>
                Services
              </NavLink>{" "}
            </div>
            <div
              className={`navbar__link ${
                active === "Projects" ? "active" : ""
              }`}
            >
              <NavLink to="/projects" onClick={menuRemove}>
                Projects
              </NavLink>
            </div>
            <div
              className={`navbar__link ${
                active === "About Us" ? "active" : ""
              }`}
            >
              <NavLink to="/about" onClick={menuRemove}>
                About Us
              </NavLink>{" "}
            </div>
            <div
              className={`navbar__link ${active === "Blog" ? "active" : ""}`}
            >
              <NavLink to="/blog" onClick={menuRemove}>
                Blog
              </NavLink>{" "}
            </div>
          </div>
          <div
            className="nav-btn"
            style={{
              float: "right",
            }}
          >
            <NavLink to="/contact" className="text-decoration-none">
              <button
                className="navbar__button"
                style={{ height: "45px", borderRadius: "10px" }}
                onClick={menuRemove}
              >
                <span className="navbar__button--title"> Contact Us</span>
              </button>
            </NavLink>
          </div>
        </div>
        <div className="mobile_menu">
          <div
            className={`menu-icon ${isOpen ? "change" : ""}`}
            onClick={toggleMenu}
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>

          {/* <span class="navbar-toggler-icon"></span> */}
          {/* </button> */}
        </div>
        {/*  */}
      </div>
      <div
        // class={`d-block ${open ? "d-block" : "hiddend"} `}
        style={{
          overflow: "hidden",
        }}
      >
        <div
          class=""
          style={{
            position: "fixed",
            zIndex: "10",
            top: "60px",
            right: "0px",
            width: "100%",
            backgroundColor: "green",
          }}
        >
          <div
            class="collapse navbar-collapse  bg-light w-100%"
            id="navbarCollapse"
            style={{
              padding: "20px",
            }}
          >
            <div class="navbar-nav">
              <a href="/" class="nav-item nav-link active mobile_nav_menus">
                Home
              </a>
              <a href="/services" class="nav-item nav-link mobile_nav_menus">
                Services
              </a>
              <a
                href="/projects"
                class="nav-item nav-link active mobile_nav_menus"
              >
                Projects
              </a>
              <a href="/blog" class="nav-item nav-link mobile_nav_menus">
                Blogs
              </a>
              <a href="/about" class="nav-item nav-link mobile_nav_menus">
                About Us
              </a>
            </div>
            <div class="navbar-nav ms-auto">
              <a href="/contact" class="nav-item nav-link">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
