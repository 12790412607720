import "./about.css";
// import Testimonials from "../Testmonials/Testimonials";
import TestimonialCard from "../Testmonials/TestimonialCards";

function About() {
  return (
    <>
      <div className="row about-hero-section" >
        <div className="col-lg-6 col-md-12 col-12 about-us-content">
          <h1 className="about-hero-section-title">
            We Craft Innovative Software Solutions
          </h1>
          <p className="about-hero-section-description">
            Are you ready to embark on a journey through the world of
            cutting-edge innovation? At Crazy Tech, we're redefining the
            possibilities of technology, one breakthrough at a time.
          </p>
          {/* <button type="submit" class="form-button">Explore Digital Solutions</button> */}
        </div>
        <div className="col-lg-6 col-md-12 about-us-image">
          <div className="about-us-rectangle">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702633928/Rectangle_1553_eiqjma.png"
              alt=""
            />
          </div>

          <div className="about-us-bracket">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089527/Crazy%20Tech/Bracket_pzydup.svg"
              alt=""
            />
          </div>

          <div className="about-us-elements">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702628385/Group_18499_wtaqfi.svg"
              alt=""
            />
          </div>

          <div className="about-us-vector">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702628413/Vector_ogkwmw.svg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="row about-hero-section">
        <div className="col-lg-6 col-md-12 about-us-image">
          <div className="about-us-background">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702637770/Group_18501_wc8xgl.svg"
              alt=""
            />
          </div>

          <div className="about-us-rectangle">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702637740/Rectangle_1553_1_f4bqia.png"
              alt=""
            />
          </div>

          <div className="about-us-elements">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702628385/Group_18499_wtaqfi.svg"
              alt=""
            />
          </div>

          <div className="about-us-vector">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1702628413/Vector_ogkwmw.svg"
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 about-us-content">
          <h1 className="about-hero-section-title">Our Company</h1>
          <p className="about-hero-section-description">
            We're thrilled to welcome you! As a committed development team, we
            specialize in providing comprehensive IT outsourcing services for
            companies of all sizes. Our objective is to deliver premium,
            tailor-made web and IT solutions that empower our clients to reach
            their objectives. Renowned for our excellence in IT solutions, our
            services encompass custom web development, mobile app development,
            software development, and more. Your go-to team for all IT
            requirements, we excel in working across various platforms and
            technologies.
          </p>
        </div>
      </div>

      <div className="about-us-approach">
        <div className="approach-area">
          <div className="approach-content">
            <h1 className="content-title">Our Approach</h1>
            <p className="content-desc">
              400+ Clients Completed Projects In 30+ Countries
            </p>
          </div>
          <div className="row approach-services">
            <div className="col-3 service-container">
              <h1 className="service-title">400+</h1>
              <p className="service-desc">Number of clients</p>
            </div>

            <div className="col-3 service-container">
              <h1 className="service-title">1200+</h1>
              <p className="service-desc">Projects Delivery</p>
            </div>

            <div className="col-3 service-container">
              <h1 className="service-title">200+</h1>
              <p className="service-desc">Employees</p>
            </div>

            <div className="col-3 service-container">
              <h1 className="service-title">2019</h1>
              <p className="service-desc">Since</p>
            </div>
          </div>
        </div>
      </div>

      <TestimonialCard />
    </>
  );
}

export default About;
