import { useEffect } from "react";

// import Testimonials from "./Testmonials/Testimonials";
import HeroSection from "./HeroSection/HeroSection";
import Customers from "./Customers/Customers";

import OurProjects from "./Ourprojects/OurProjects";
import Myservices from "./Ourservices/OurServices";
import TestimonialCard from "./Testmonials/TestimonialCards";

function Home() {

  useEffect(() => {
        const scaleContainer = () => {
          const containerImage = document.querySelectorAll('.carousel-item-image');
          const containerText = document.querySelectorAll(".carousel-caption .content");
          const containerItem = document.querySelectorAll(".carousel-item");

          if (containerImage.length > 0  && containerText.length > 0) {
            containerItem.forEach((item, it) => {    
              if(item.classList.contains("active")) {
                containerImage.forEach((image, im) => {
                  if(it === im) {
                    image.style.transform = 'scale(1.2, 1.2)';
                    image.style.transition = "all 1s ease-in";
                  }
                })
                containerText.forEach((text, te) => {
                  if(it === te) {
                    text.style.transform = "translateY(0)";
                    text.style.transition = "all 1.5s ease-in";
                  }
                })
              }
            })
          }
        };
    
        scaleContainer();
  }, [])

  return (
    <>
    <div className="home" style={{
      overflow: "hidden",
      position: "relative",
    }}>
      {/* <HeroSection check={true} height='100%' margin="180px 0 260px 0" heading="Unleash the Future with" subheadings="Crazy Tech!" description=" Are you ready to embark on a journey through the world of
              cutting-edge innovation? At Crazy Tech, we're redefining the
  possibilities of technology, one breakthrough at a time." buttonDesc="Start A Project"/> */}
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        {/* <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
</div> */}
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701087884/Crazy%20Tech/Hero_Section_Background_1_2x_i0swdh.png"
              class="d-block w-100 carousel-item-image"
              alt="..."
            />
            <div class="carousel-caption">
              <HeroSection
                check={true}
                height="100%"
                margin="170px 0 260px 0"
                heading="Transform Your World with"
                subheadings="Crazy Tech!"
                description=" Are you ready to embark on a journey through the world of
        cutting-edge innovation? At Crazy Tech, we're redefining the
        possibilities of technology, one breakthrough at a time."
                buttonDesc="Get a Quote"
              />
            </div>
          </div>

          {/* <div class="carousel-item">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703491294/Crazy%20Tech/slider2_vhxxnw.png"
              class="d-block w-100 carousel-item-image"
              alt="..."
            />
            <div class="carousel-caption">
            <HeroSection
            check={true}
            height="100%"
            margin="180px 0 260px 0"
            heading="Unleash the Future"
            subheadings="Crazy Tech!"
            description=" Are you ready to embark on a journey through the world of
    cutting-edge innovation? At Crazy Tech, we're redefining the
    possibilities of technology, one breakthrough at a time."
            buttonDesc="Start A Project"
          />
            </div>
          </div>

          <div class="carousel-item">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703491293/Crazy%20Tech/slider1_knzrfv.png"
              class="d-block w-100 carousel-item-image"
              alt="..."
            />
            <div class="carousel-caption">
            <HeroSection
            check={true}
            height="100%"
            margin="180px 0 260px 0"
            heading="Unleash the Future"
            subheadings="Crazy Tech!"
            description=" Are you ready to embark on a journey through the world of
    cutting-edge innovation? At Crazy Tech, we're redefining the
    possibilities of technology, one breakthrough at a time."
            buttonDesc="Start A Project"
          />
            </div>
</div> */}
        </div>
      </div>

      <Customers />
      <Myservices check={true} />
      <OurProjects />
      {/* <Testimonials /> */}
    </div>
    <div style={{
      width: "100%",
      overflow: "hidden"
    }}> 
      <TestimonialCard/>

    </div>

    </>
  );
}

export default Home;
