import HeroSection from "./HeroSection/HeroSection";
import { useEffect } from "react";
import AOS from "aos";
import "../style/services.css"
import { IconCheck } from '@tabler/icons-react';

function Services() {
  useEffect(() => {
    const scaleContainer = () => {
      const containerImage = document.querySelectorAll(".carousel-item-image");
      const containerText = document.querySelectorAll(".carousel-caption .service");
      const containerItem = document.querySelectorAll(".carousel-item");

      if (containerImage.length > 0 && containerText.length > 0) {
        containerItem.forEach((item, it) => {
          if (item.classList.contains("active")) {
            containerImage.forEach((image, im) => {
              if (it === im) {
                image.style.transform = "scale(1.2, 1.2)";
                image.style.transition = "all 1s ease-in";
              }
            });
            containerText.forEach((text, te) => {
              if (it === te) {
                text.style.transform = "translateY(0)";
                text.style.transition = "all 1.5s ease-in";
              }
            });
          }
        });
      }
    };

    scaleContainer();
  }, []);

  useEffect(() => {
    AOS.init();
  }, [])


  return (
    <div className="home">
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
        style={{height: "500px"}}
      >
        {/* <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
</div> */}
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701087884/Crazy%20Tech/Hero_Section_Background_1_2x_i0swdh.png"
              class="d-block w-100 carousel-item-image"
              alt="..."
              style={{height: "500px"}}
            />
            <div class="carousel-caption">
              <HeroSection
                checkTow={true}
                height="500px"
                margin="0"
                title2="We Offer!"
                heading=""
                subheadings="Services "
                description=" Empowering Innovation Through Cutting-Edge Solutions"
                buttonDesc="Start A Project"
                parent_class="service"
              />
            </div>
          </div>

          {/* <div class="carousel-item">
        <img
          src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703491294/Crazy%20Tech/slider2_vhxxnw.png"
          class="d-block w-100 carousel-item-image"
          alt="..."
        />
        <div class="carousel-caption">
        <HeroSection
        check={true}
        height="100%"
        margin="180px 0 260px 0"
        heading="Unleash the Future"
        subheadings="Crazy Tech!"
        description=" Are you ready to embark on a journey through the world of
cutting-edge innovation? At Crazy Tech, we're redefining the
possibilities of technology, one breakthrough at a time."
        buttonDesc="Start A Project"
      />
        </div>
      </div>

      <div class="carousel-item">
        <img
          src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703491293/Crazy%20Tech/slider1_knzrfv.png"
          class="d-block w-100 carousel-item-image"
          alt="..."
        />
        <div class="carousel-caption">
        <HeroSection
        check={true}
        height="100%"
        margin="180px 0 260px 0"
        heading="Unleash the Future"
        subheadings="Crazy Tech!"
        description=" Are you ready to embark on a journey through the world of
cutting-edge innovation? At Crazy Tech, we're redefining the
possibilities of technology, one breakthrough at a time."
        buttonDesc="Start A Project"
      />
        </div>
</div> */}
        </div>
      </div>

      <section className="my-our-services pb-4">
        <div className="container">
          <div className="servies-main-title">
            <h1 className="text-center">Services We Offer</h1>
          </div>
          <div className="row">
            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4">
              <div className="services-cards" data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4" >
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703501860/Crazy%20Tech/Development-01_nggzid.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Software Development</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Tailored solutions for specific business needs
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Enterprise software development
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Legacy system modernization
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703504576/Crazy%20Tech/Custom_Software_Development-01_hb4p6e.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Custom Software Development</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />

                      Tailored solutions for specific business needs
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Enterprise software development
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Legacy system modernization
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703501860/Crazy%20Tech/Development-01_nggzid.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Web Development</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Frontend development (UI/UX design)
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={30} clase="checkIcon" style={{marginRight: "10px"}} />
                      Backend development (server-side scripting, database
                      management)
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Full-stack development
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089525/Crazy%20Tech/developer_mode_nhpu7c.svg"
                      alt="Developer Mode"
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Mobile App Development</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      iOS app development
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Android app development
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Cross-platform app development (React Native, Flutter)
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703498944/Crazy%20Tech/Cloud_Servecis-01_xgama8.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Cloud Services</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={30} clase="checkIcon" style={{marginRight: "10px"}} />
                      Cloud infrastructure management (AWS, Azure, Google Cloud)
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Cloud migration and deployment
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Cloud consulting and optimization
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703504576/Crazy%20Tech/Analysis_3-01_gif4ar.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Data Analytics</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Big data solutions and analytics
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Business intelligence and reporting
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Data visualization services
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4">
              <div className="services-cards" data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502002/Crazy%20Tech/SQA-01_mptper.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Quality Assurance (QA) and Testing</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Manual testing
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Automated testing (test automation frameworks)
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Quality control and assurance processes
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703504576/Crazy%20Tech/ui_ux-01_1_tphwge.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">UI/UX Design</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      User interface (UI) design
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      User experience (UX) design
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Prototyping and wireframing
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502024/Crazy%20Tech/Cybersecurity_2-01_abdhlw.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Cybersecurity</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Network security
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Data encryption services
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Security audits and assessments
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703503391/Crazy%20Tech/Emerging_Technologies-01_gyvasp.png"
                      alt="Developer Mode"
                      width={45}
                      height={43}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Emerging Technologies</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Blockchain development
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Artificial Intelligence (AI) and Machine Learning (ML)
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Internet of Things (IoT) solutions
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502769/Crazy%20Tech/maintenance_and_support-01_1_ownq99.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Maintenance and Support</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Software maintenance and updates
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Bug fixing and troubleshooting
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Customer support services
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703500717/Crazy%20Tech/Training_and_Documentation-01_k84vrp.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Training and Documentation</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      User training for software applications
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Technical documentation services
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Knowledge transfer and workshops
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4">
              <div className="services-cards" data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502769/Crazy%20Tech/product_engineering_3-01_l0mrfh.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Product Engineering</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Prototyping and product design
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      New software product development
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Feature enhancement and product updates
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502036/Crazy%20Tech/Augmented_Reality_AR_and_Virtual_Reality_VR_-01_zgi72u.png"
                      alt="Developer Mode"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">
                      Augmented Reality (AR) and Virtual Reality (VR)
                    </h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      AR and VR application development
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Integration of AR/VR in business processes
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Immersive technology solutions
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502011/Crazy%20Tech/e-commerce_solutions-01_xgetjf.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">E-commerce Solutions</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Development of custom e-commerce platforms
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Integration with payment gateways
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      E-commerce analytics and optimization
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502025/Crazy%20Tech/CMS-01_ongjhm.png"
                      alt="Developer Mode"
                      width={43}
                      height={43}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Content Management Systems (CMS)</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      CMS development and customization
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Integration with existing systems
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      User-friendly content authoring tools
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703505143/Crazy%20Tech/platforms-01_abeqzo.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">Cross-Platform Solutions</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Development of solutions compatible with multiple
                      platforms
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Code sharing and reusability
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Consistent user experience across devices
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502037/Crazy%20Tech/api-01_npy05e.png"
                      alt="Developer Mode"
                      width={45}
                      height={43}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">API Development and Integration</h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Custom API development
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Integration with third-party services
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      API documentation and support
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      API Testing
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Accessibility Testing
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 col-md-4 col-sm-6 col-12 mt-4 ">
              <div className="services-cards " data-aos="fade-right" data-aos-duration="900">
                <div className="card p-4  ">
                  <div className="card-image-logo">
                    <img
                      src="https://res.cloudinary.com/dqg52thyo/image/upload/v1703502025/Crazy%20Tech/Collaboration-01_vieyis.png"
                      alt="Developer Mode"
                      width={45}
                      height={45}
                    />
                  </div>
                  <div className="service-lists">
                    <h6 className="mt-4">
                      Collaboration and Communication Tools
                    </h6>
                  </div>
                  <div className="services-card-list">
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Development of collaboration platforms
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Communication tool integration
                    </div>
                    <div className="check-icone services-list">
                      <IconCheck stroke={2} size={20} clase="checkIcon" style={{marginRight: "10px"}} />
                      Project management solutions
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
